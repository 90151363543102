import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  cn,
  Divider,
  RiArrowRightUpLine,
  RiCompass4Line,
  RiMapPinTimeLine,
  Stack,
} from '@landler/tw-component-library';
import { centerOfMass } from '@turf/turf';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';

import { Fact } from '@/api/rest/resources/conservation';
import { CapsuleTrend } from '@/components';
import { Area, useAreaDisplay } from '@/utils/area';

import { FactCapsule } from '../../../components/conservation/components/FactCapsule';
import { PROJECT_CELLS_SOURCE_ID } from '../constants';
import { useControlsContext } from '../hooks/useControlsForm';

export const InfoPanel = () => {
  const { t } = useTranslation();

  const controlsForm = useControlsContext();
  const cell = controlsForm.watch('cell');

  const mapRef = useMap();

  const areaDisplay = useAreaDisplay(
    new Area({ value: cell?.properties.area_km2 ?? 0, dimension: 'squareKilometers' }),
  );

  if (!cell) {
    return null;
  }

  const cellCoordinates = centerOfMass(cell).geometry.coordinates.map((c) => c.toFixed(6));
  const cellCoordinatesDisplay = `${cellCoordinates[0]} S, ${cellCoordinates[1]} E`;

  const closePanel = () => {
    controlsForm.setValue('cell', undefined);

    const map = mapRef?.current;

    if (!map) {
      return;
    }

    map.setFeatureState({ source: PROJECT_CELLS_SOURCE_ID, id: cell.id }, { active: false });
  };

  return (
    <Stack className='h-full w-[413px] bg-white-100' data-testid='interactive-map-info-panel'>
      <Stack spacing={2} className='flex-1 overflow-auto pb-8'>
        <Stack spacing={4} className='mx-6 mt-8 rounded-xl bg-neutral-black-4 p-4'>
          <Stack direction='row' centerMain spacing={8}>
            <InfoStack
              label={t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.cellId')}
              content={cell.properties.id}
            />
            <InfoStack
              label={t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.unitSize')}
              content={areaDisplay}
            />
          </Stack>
          <InfoStack
            label={t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.cellCoordinates')}
            content={cellCoordinatesDisplay}
          />
        </Stack>

        <Accordion className='flex flex-col px-4' type='single' collapsible>
          <AccordionItem value='about'>
            <AccordionTrigger>
              {t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.ecologicalIntegrity')}
            </AccordionTrigger>
            <AccordionContent>
              <EcologicalIntegrity />
            </AccordionContent>
          </AccordionItem>

          <AccordionDivider />

          <AccordionItem value='species'>
            <AccordionTrigger>
              {t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.habitatIntactness')}
            </AccordionTrigger>
            <AccordionContent>
              <HabitatIntactness />
            </AccordionContent>
          </AccordionItem>

          <AccordionDivider />

          <AccordionItem value='community'>
            <AccordionTrigger>
              {t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.floraAndFauna')}
            </AccordionTrigger>
            <AccordionContent>
              <FloraAndFauna />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </Stack>
      <Stack
        direction='row'
        className={cn('justify-end justify-self-end px-6 py-4', '[box-shadow:_0px_-10px_10px_0px_rgba(0,0,0,0.05)]')}
      >
        <Button variant='outline' onClick={closePanel}>
          {t('global.ui.buttons.close')}
        </Button>
      </Stack>
    </Stack>
  );
};

const InfoStack = ({ label, content }: { label: React.ReactNode; content: React.ReactNode }) => {
  return (
    <Stack spacing={2}>
      <span className='typography-overline text-text-secondary'>{label}</span>
      <span className='typography-h4'>{content}</span>
    </Stack>
  );
};

const AccordionDivider = () => <Divider className='mx-4' />;

const EcologicalIntegrity = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={7}>
      <FactCapsule
        // eslint-disable-next-line sonarjs/no-duplicate-string
        label={t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.latestAverageValue', {
          year: new Date().getFullYear() - 1,
        })}
        fact={
          {
            value: '0.998',
            unit: 'none',
          } as Fact
        }
        capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
      />
      <FactCapsule
        label={t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.fiveYearChange')}
        signed
        trend={<CapsuleTrend variant='positive'>+8.7%</CapsuleTrend>}
        fact={
          {
            value: '0.06',
            unit: 'none',
          } as Fact
        }
        capsuleIconProps={{ icon: <RiArrowRightUpLine />, variant: 'default' }}
      />
    </Stack>
  );
};

const HabitatIntactness = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={7}>
      <FactCapsule
        label={t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.latestAverageValue', {
          year: new Date().getFullYear() - 1,
        })}
        fact={
          {
            value: '0.998',
            unit: 'none',
          } as Fact
        }
        capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
      />
      <FactCapsule
        label={t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.fiveYearChange')}
        signed
        trend={<CapsuleTrend variant='positive'>+8.7%</CapsuleTrend>}
        fact={
          {
            value: '0.06',
            unit: 'none',
          } as Fact
        }
        capsuleIconProps={{ icon: <RiArrowRightUpLine />, variant: 'default' }}
      />
      <FactCapsule
        label={t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.latestRegionalAverage', {
          year: new Date().getFullYear() - 1,
        })}
        fact={
          {
            value: '0.865',
            unit: 'none',
          } as Fact
        }
        capsuleIconProps={{ icon: <RiMapPinTimeLine />, variant: 'default' }}
      />
    </Stack>
  );
};

const FloraAndFauna = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={7}>
      <FactCapsule
        label={t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.latestAverageValue', {
          year: new Date().getFullYear(),
        })}
        fact={
          {
            value: '0.998',
            unit: 'none',
          } as Fact
        }
        capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
      />
      <FactCapsule
        label={t('shared.projects.project.conservation.interactiveMap.infoPanel.labels.latestRegionalAverage', {
          year: new Date().getFullYear(),
        })}
        fact={
          {
            value: '0.865',
            unit: 'none',
          } as Fact
        }
        capsuleIconProps={{ icon: <RiMapPinTimeLine />, variant: 'default' }}
      />
    </Stack>
  );
};
