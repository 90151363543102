import { ButtonLink, RiArrowRightSLine, RiCompass4Line, Stack, StackProps } from '@landler/tw-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ChartHeading, ChartHeadingProps } from '@/components/ChartsV1_2/components/ChartHeading';
import { EcologicalIntegrityIndicatorsExplainer } from '@/components/NCAExplainersV1_2/EcologicalIntegrityIndicatorsExplainer';
import { usePathSet } from '@/hooks/usePathSet';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { buildPath } from '@/utils/buildPath';

import { FactCapsule } from '../components/FactCapsule';
import { useFact } from '../hooks/useFact';
import { useStaticImage } from '../hooks/useStaticImage';

export const LandMonitoringPage = () => {
  const { t } = useTranslation();

  const pathSet = usePathSet();
  const projectId = useProjectId();

  const habitatIntactnessMapImage = useStaticImage('habitat_intactness');
  const floraAndFaunaMapImage = useStaticImage('flora_and_fauna');

  const habitatIntactnessProjectAverage = useFact('r1_conservation_habitat_intactness_average');
  const habitatIntactnessBaseline = useFact('r1_conservation_habitat_intactness_baseline_2019');
  const floraAndFaunaProjectAverage = useFact('r1_conservation_flora_and_fauna_average');

  return (
    <Stack spacing={8}>
      <Stack direction='row' centerMain className='justify-between'>
        <span className='typography-overline'>
          {t('shared.ncaDetail.details.landMonitoring.ecologicalIntegrityIndicators')}
        </span>
        <div className='hidden md:[display:_initial]'>
          <EcologicalIntegrityIndicatorsExplainer />
        </div>
      </Stack>

      <Stack spacing={8} className='md:flex-row'>
        <Card
          data-testid='habitat-intactness'
          title={t('shared.ncaDetail.details.landMonitoring.habitatIntactness.title')}
          heading={{ heading: t('shared.ncaDetail.details.landMonitoring.habitatIntactness.heading') }}
          image={{
            url: habitatIntactnessMapImage,
            alt: t('shared.ncaDetail.details.landMonitoring.habitatIntactness.mapImageAlt'),
          }}
          detailsPagePath={buildPath(pathSet.habitatIntactness, { pathParams: { projectId } })}
          stats={
            <Stack spacing={6}>
              <Stack spacing={10} className='py-3'>
                <FactCapsule
                  label={t('shared.ncaDetail.details.ecologicalIntegrity.labels.projectAverageValue')}
                  capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
                  fact={habitatIntactnessProjectAverage}
                />
                <FactCapsule
                  label={t('shared.ncaDetail.ecologicalIntegrity.breakdown.habitatIntactness.baselinePrevFiveYears', {
                    year: new Date().getFullYear() - 5,
                  })}
                  capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
                  fact={habitatIntactnessBaseline}
                />
              </Stack>
              <Stack spacing={2} className='p-2'>
                <div className='h-3 w-full max-w-[222px] rounded-sm bg-gradient-to-r from-[#FFF9C5] to-[#8E3104]' />
                <Stack direction='row' className='justify-between'>
                  {[0.0, 0.2, 0.4, 0.6, 0.8, 1.0].map((num) => (
                    <span key={num} className='typography-caption text-text-secondary'>
                      {Intl.NumberFormat(window.navigator.language, {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      }).format(num)}
                    </span>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          }
        />
        <Card
          data-testid='flora-and-fauna'
          title={t('shared.ncaDetail.details.landMonitoring.floraAndFauna.title')}
          heading={{ heading: t('shared.ncaDetail.details.landMonitoring.floraAndFauna.heading') }}
          image={{
            url: floraAndFaunaMapImage,
            alt: t('shared.ncaDetail.details.landMonitoring.floraAndFauna.mapImageAlt'),
          }}
          detailsPagePath={buildPath(pathSet.floraAndFauna, { pathParams: { projectId } })}
          stats={
            <Stack spacing={6}>
              <Stack spacing={10} className='py-3'>
                <FactCapsule
                  label={t('shared.ncaDetail.details.landMonitoring.floraAndFauna.projectAverageValue')}
                  capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
                  fact={floraAndFaunaProjectAverage}
                />
              </Stack>
              <Stack spacing={2} className='p-2'>
                <div className='h-3 w-full max-w-[222px] rounded-sm bg-gradient-to-r from-[#E8E7F3] to-[#595A90]' />
                <Stack direction='row' className='justify-between'>
                  {[0.0, 0.2, 0.4, 0.6, 0.8, 1.0].map((num) => (
                    <span key={num} className='typography-caption text-text-secondary'>
                      {Intl.NumberFormat(window.navigator.language, {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      }).format(num)}
                    </span>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          }
        />
      </Stack>
    </Stack>
  );
};

const Card = ({
  // @ts-expect-error data-testid is a valid prop
  'data-testid': dataTestId,
  title,
  heading,
  stats,
  image,
  detailsPagePath,
  ...delegated
}: StackProps & {
  title: React.ReactNode;
  heading: ChartHeadingProps;
  stats: React.ReactNode;
  image: { url: string; alt: string };
  detailsPagePath: string;
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <Stack spacing={8} className='flex-1 rounded-[32px] bg-white-100 p-8' data-testid={dataTestId} {...delegated}>
      <span className='typography-h3'>{title}</span>
      <Stack spacing={6} className='rounded-2xl bg-bg-light-grey p-6'>
        <ChartHeading {...heading} />
        <Stack spacing={6} centerMain className='flex-col-reverse justify-between md:flex-row'>
          {stats}

          <img
            data-testid={`${dataTestId}-image`}
            src={image.url}
            className='max-h-[265px] min-w-0 max-w-full'
            alt={image.alt}
          />
        </Stack>

        <Stack spacing={4}>
          <ButtonLink
            to={detailsPagePath}
            state={{ from: pathname }}
            variant='outline'
            rightAdornment={<RiArrowRightSLine size={20} />}
            className='self-start'
          >
            {t('shared.ncaDetail.details.ecologicalIntegrity.labels.seeMoreDetails')}
          </ButtonLink>
        </Stack>
      </Stack>
    </Stack>
  );
};
