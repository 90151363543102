import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import bbox from '@turf/bbox';
import { Feature, FeatureCollection, Geometry } from 'geojson';
import { LngLatBoundsLike } from 'mapbox-gl';

import { CellProperties } from '@/api/rest/resources/conservation';
import { useMembershipType } from '@/hooks/useMembershipType';

import { useProjectKey } from './useProjectKey';

export const getPolygonByProjectKey = async (projectKey: string) => {
  const module = await import(`../mockData/${projectKey}/polygon.json`);
  return module.default as FeatureCollection;
};

export const useProjectBoundary = () => {
  const membershipType = useMembershipType();
  const projectKey = useProjectKey();

  return useSuspenseQuery({
    queryKey: [membershipType, 'project', 'conservation', projectKey, 'boundary'],
    queryFn: () => getPolygonByProjectKey(projectKey),
  });
};

export const useProjectBounds = () => {
  const membershipType = useMembershipType();
  const projectKey = useProjectKey();

  return useSuspenseQuery({
    queryKey: [membershipType, 'project', 'conservation', projectKey, 'boundary', 'bounds'],
    queryFn: () => getPolygonByProjectKey(projectKey).then((polygon) => bbox(polygon) as LngLatBoundsLike),
  });
};

export const getProjectCells = async ({ projectKey, year }: { projectKey: string; year: string }) => {
  const module = await import(`../mockData/${projectKey}/cells_${year}.json`);
  return module.default as FeatureCollection<Geometry, CellProperties>;
};

export const useProjectCells = ({ year }: { year: string }) => {
  const membershipType = useMembershipType();
  const projectKey = useProjectKey();

  return useQuery({
    queryKey: [membershipType, 'project', 'conservation', projectKey, 'cells', year],
    queryFn: async () => {
      const cells = await getProjectCells({ projectKey, year });
      return {
        ...cells,
        features: cells.features.map((feature, index) => ({
          ...feature,
          id: index + 1,
        })),
      } as FeatureCollection<Geometry, CellProperties>;
    },
  });
};

export const useProjectCellsById = ({ year }: { year: string }) => {
  const membershipType = useMembershipType();
  const projectKey = useProjectKey();

  const cells = useProjectCells({ year });

  const cellsById = cells.data?.features.reduce((acc, curr) => {
    acc[curr.properties.id] = curr;
    return acc;
  }, {} as Record<string, Feature<Geometry, CellProperties>>);

  return useQuery({
    queryKey: [membershipType, 'project', 'conservation', projectKey, 'cells', 'id', year],
    queryFn: () => cellsById,
  });
};
