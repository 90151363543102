import * as AccordionPrimitive from '@radix-ui/react-accordion';
import React from 'react';

import { RiArrowDownSLine } from '../Icon/Icon';
import { cn } from '../utils';

export const Accordion = AccordionPrimitive.Root;

export const AccordionItem = React.forwardRef<
  React.ComponentRef<typeof AccordionPrimitive.Item>,
  React.ComponentProps<typeof AccordionPrimitive.Item>
>(({ children, className, ...props }, forwardedRef) => (
  <AccordionPrimitive.Item
    className={cn('overflow-hidden rounded-xl bg-white-100', className)}
    {...props}
    ref={forwardedRef}
  >
    {children}
  </AccordionPrimitive.Item>
));
AccordionItem.displayName = 'AccordionItem';

export const AccordionTrigger = React.forwardRef<
  React.ComponentRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentProps<typeof AccordionPrimitive.Trigger>
>(({ children, className, ...props }, forwardedRef) => (
  <AccordionPrimitive.Header className='flex'>
    <AccordionPrimitive.Trigger
      className={cn(
        'group/acc-trigger typography-h4 flex flex-1 items-center justify-between px-4 py-6 outline-none',
        'transition-colors duration-150 hover:bg-primary-12',
        className,
      )}
      {...props}
      ref={forwardedRef}
    >
      {children}
      <RiArrowDownSLine className='transition-transform group-data-[state=open]/acc-trigger:rotate-180' aria-hidden />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = 'AccordionTrigger';

export const AccordionContent = React.forwardRef<
  React.ComponentRef<typeof AccordionPrimitive.Content>,
  React.ComponentProps<typeof AccordionPrimitive.Content>
>(({ children, className, ...props }, forwardedRef) => (
  <AccordionPrimitive.Content
    className={cn(
      'overflow-hidden',
      'data-[state=closed]:animate-accordionClose',
      'data-[state=open]:animate-accordionOpen',
    )}
    {...props}
    ref={forwardedRef}
  >
    <div className={cn('w-full px-4 py-6', className)}>{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = 'AccordionContent';
