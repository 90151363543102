import { ButtonLink, RiRoadMapLine } from '@landler/tw-component-library';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layer, LayerProps, MapRef, Source } from 'react-map-gl';

import { Map } from '@/components/Map';
import { MAP_MAX_ZOOM, MAP_OVERVIEW_PADDING_MOBILE } from '@/config/constants';
import { usePathSet } from '@/hooks/usePathSet';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { buildPath } from '@/utils/buildPath';
import { isTestEnv } from '@/utils/isTestEnv';

import { useProjectBoundary, useProjectBounds, useProjectCells } from '../hooks/useProjectPolygon';

const PROJECT_POLYGON_SOURCE_ID = 'project-polygon';
const PROJECT_CELLS_SOURCE_ID = 'project-cells';

export const ProjectMap = () => {
  const { t } = useTranslation();
  const pathSet = usePathSet();

  /**
   * The Map's onLoad callback is not triggered in the test environment, and so
   * the map remains hidden. We set the default value of isMapReady to true in
   * the test environment.
   */
  const [isMapReady, setIsMapReady] = useState(isTestEnv);

  const mapRef = useRef<MapRef | null>(null);

  const projectId = useProjectId();
  const { data: projectBounds } = useProjectBounds();
  const { data: projectPolygon } = useProjectBoundary();
  const { data: projectCells } = useProjectCells({ year: '2023' });

  return (
    <div
      className='h-[187px] w-full overflow-hidden rounded-3xl bg-primary-50 sm:h-[494px] md:h-full'
      data-testid='project-map'
    >
      <Map
        ref={mapRef}
        cooperativeGestures
        data-testid='conservation-project-map-gl'
        initialViewState={{
          bounds: projectBounds,
          fitBoundsOptions: { padding: MAP_OVERVIEW_PADDING_MOBILE, maxZoom: MAP_MAX_ZOOM },
        }}
        onLoad={() => setIsMapReady(true)}
        style={{ opacity: isMapReady ? 1 : 0, transition: 'opacity 0.2s' }}
        logoPosition='bottom-left'
      >
        {projectPolygon && (
          <Source id={PROJECT_POLYGON_SOURCE_ID} type='geojson' data={projectPolygon}>
            <Layer {...polygonOutlineLayer} />
          </Source>
        )}
        {projectCells && (
          <Source id={PROJECT_CELLS_SOURCE_ID} type='geojson' data={projectCells}>
            <Layer {...cellsOutlineLayer} />
          </Source>
        )}

        {/* Bottom decoration */}
        <div className='pointer-events-none absolute bottom-0 h-32 w-full bg-gradient-to-t from-neutral-black-60 to-transparent' />

        <ButtonLink
          to={buildPath(pathSet.interactiveMap, { pathParams: { projectId } })}
          className='absolute bottom-6 right-5'
          color='white'
          size='small'
          leftAdornment={<RiRoadMapLine size={20} />}
        >
          {t('shared.projects.project.conservation.overview.labels.interactiveMap')}
        </ButtonLink>
      </Map>
    </div>
  );
};

const polygonOutlineLayer: LayerProps = {
  id: 'polygon-line',
  type: 'line',
  source: PROJECT_POLYGON_SOURCE_ID,
  paint: {
    'line-width': 1,
    'line-opacity': 0.6,
    'line-color': 'rgb(255, 255, 255)',
  },
};

const cellsOutlineLayer: LayerProps = {
  id: 'cells-line',
  type: 'line',
  source: PROJECT_CELLS_SOURCE_ID,
  paint: {
    'line-width': 1,
    'line-opacity': 0.6,
    'line-color': 'rgb(255, 255, 255)',
  },
};
